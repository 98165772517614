import { state } from ":mods";
import { createEffect } from "solid-js";
import { getBlogPost } from "../../api";
export function BlogPostPage(props: { id: number; events?: { onBack?: () => void } }) {
  const $post_details = state.create<any>({});
  const $db_blog = state.createAsync([getBlogPost, props.id]);
  $db_blog.on((event, { value }) => {
    $post_details.set(value?.data);
  });
  // $post_details.value?.title
  // $post_details.value?.article
  const images = ["/images/figma_image_rect.png"];
  const blogData = {
    id: 1,
    topic: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.",
    image: images[0],
    description:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. ",
    article:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. ",
  };
  return (
    <main class="w-screen">
      <section class="flex flex-col items-center bg#paper pt-100px">
        <div class="font-bold text-20px justify-start items-start text#p cursor-pointer flex gap-3px w-846px  ">
          <p class="">{"<"}</p>
          <p onclick={() => props.events?.onBack?.()}>Back</p>
        </div>

        <h1 class="text-61px font-bold leading-67px w-846px mt-40px mb-80px">{blogData.topic}</h1>

        <section class="flex flex-col gap-40px justify-center items-center">
          <div class="bg#p h-432px w-846px" />

          <article class="flex flex-col gap-40px w-846px text-16px">{blogData.description}</article>
          <p class="text-50px font-bold leading-67px w-846px mt-40px mb-80px">"{blogData.title}"</p>
          <article class="flex flex-col gap-40px w-846px text-16px">{blogData.article}</article>
        </section>
        <hr class="max-w-screen w-screen mt-120px h-2px bg#p" />
      </section>
    </main>
  );
}
